import React, { useState, useEffect, useRef } from 'react';

function App() {
  const [imageUrl, setImageUrl] = useState('');
  const [transcript, setTranscript] = useState('');
  const [listening, setListening] = useState(false);
  const [isWsConnected, setIsWsConnected] = useState(false);
  const wsRef = useRef(null);
  const recognition = new window.webkitSpeechRecognition();

  useEffect(() => {
    wsRef.current = new WebSocket('wss://xmas-api-2a44c1be2339.herokuapp.com/ws');
    wsRef.current.onopen = () => {
      console.log('WebSocket Connected');
      setIsWsConnected(true);
    };
    wsRef.current.onmessage = (event) => {
      console.log('WebSocket Message: ', event)
      try {
        const message = JSON.parse(event.data);
        if (message.type === 'image_update') setImageUrl(message.url);
      } catch (e) {
        console.error('Error parsing WebSocket message:', e);
      }
    };
    wsRef.current.onerror = () => setIsWsConnected(false);
    wsRef.current.onclose = () => setIsWsConnected(false);

    return () => { if (wsRef.current) wsRef.current.close(); };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === 'Space') {
        event.preventDefault();
        if (!listening) startListening();
      } else if (event.key === 'c') {
        setTranscript('');
      }
    };

    const handleKeyUp = (event) => {
      if (event.code === 'Space') {
        event.preventDefault();
        if (listening) stopListening();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [listening]);

  const updateTranscript = (newTranscript) => {
    setTranscript(prevTranscript => {
      const updatedTranscript = prevTranscript ? `${prevTranscript} ${newTranscript}` : newTranscript;
      sendWebSocketMessage(updatedTranscript);
      return updatedTranscript;
    });
  };

  const sendWebSocketMessage = (message) => {
    if (wsRef.current) {
      wsRef.current.send(JSON.stringify({ type: 'transcript', transcript: `transcript: ${message}` }));
    }
  };

  const startListening = () => {
    if ('webkitSpeechRecognition' in window) {
      recognition.lang = 'en-US';
      recognition.continuous = false;
      recognition.interimResults = false;
      recognition.onresult = (event) => updateTranscript(event.results[0][0].transcript);
      recognition.onerror = (event) => console.error('Speech Recognition Error: ', event.error);
      recognition.start();
      setListening(true);
    } else console.log('Browser does not support Speech Recognition.');
  };

  const stopListening = () => {
    recognition.stop();
    setListening(false);
  };

  const handleUpdateTranscriptClick = () => {

    const userInput = prompt("Please enter a description:", transcript);
    if (userInput !== null) {
      setTranscript(prevTranscript => {
        const updatedTranscript = userInput;
        sendWebSocketMessage(updatedTranscript);
        return updatedTranscript;
      })
    }
  };

  return (
    <div
      className="App"
      style={{
        backgroundImage: `url(${imageUrl})`,
        height: '100vh',
        backgroundSize: 'contain',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'black',
      }}
      onClick={handleUpdateTranscriptClick}
    >
      <p style={{ color: listening ? 'white': 'black' }}>Listening: {listening}</p>
      <p style={{ backgroundColor: 'white' }}>{transcript}</p>
    </div>
  );
}

export default App;
